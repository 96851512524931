import { useEffect, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { RETROSPECTIVE_BLUEPRINT_COMPLETE_KEY } from '../../../common/constants';
import type { ConfluenceBlueprint } from '../types';

const spaceKeyToBlueprintCache = new Map<string, ConfluenceBlueprint | undefined>();

export const getBlueprintOld = async (
	cloudId: string,
	spaceKey: string,
	blueprintModuleCompleteKey: string,
): Promise<ConfluenceBlueprint> => {
	let blueprints;
	const url = `/gateway/api/ex/confluence/${cloudId}/wiki/rest/create-dialog/1.0/blueprints/web-items?spaceKey=${spaceKey}`;
	try {
		blueprints = await fetchJson<ConfluenceBlueprint[]>(url);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'createRetroGetBlueprint',
				packageName: 'jiraConfluenceSprintRetros',
				teamName: 'mdt-zora',
			},
			error: error || `Unknown error calling getBlueprint for space: ${spaceKey}`,
		});
		throw error || new Error(`Error calling ${url}`);
	}
	const found = blueprints.find(
		(blueprint) => blueprint.blueprintModuleCompleteKey === blueprintModuleCompleteKey,
	);
	if (!found) {
		throw new Error(
			`Fetching blueprints succeeded, but blueprint: ${blueprintModuleCompleteKey} was not found for space: ${spaceKey}`,
		);
	}
	return found;
};

const findRetroBlueprint = async (
	cloudId: string,
	spaceKey: string,
): Promise<ConfluenceBlueprint | undefined> => {
	const url = `/gateway/api/ex/confluence/${cloudId}/wiki/rest/create-dialog/1.0/blueprints/web-items?spaceKey=${spaceKey}`;
	try {
		const blueprints = await fetchJson<ConfluenceBlueprint[]>(url);
		const retroBlueprint = blueprints.find(
			(bp) => bp.blueprintModuleCompleteKey === RETROSPECTIVE_BLUEPRINT_COMPLETE_KEY,
		);

		if (!retroBlueprint) {
			fireErrorAnalytics({
				meta: {
					id: 'findRetroBlueprintMissing',
					packageName: 'jiraConfluenceSprintRetros',
					teamName: 'mdt-zora',
				},
				error: new Error(
					`Fetching blueprints succeeded, but blueprint: ${RETROSPECTIVE_BLUEPRINT_COMPLETE_KEY} was not found for space: ${spaceKey}`,
				),
				// Skipping Sentry because this is an expected error, but we still want to log it
				skipSentry: true,
			});
		}

		return retroBlueprint;
	} catch (error: unknown) {
		fireErrorAnalytics({
			meta: {
				id: 'findRetroBlueprint',
				packageName: 'jiraConfluenceSprintRetros',
				teamName: 'mdt-zora',
			},
			error:
				error instanceof Error
					? error
					: new Error(`Unknown error calling getBlueprint for space: ${spaceKey}`),
		});
		return undefined;
	}
};

export const useConfluenceBlueprints = (spaceKey: string | undefined) => {
	const cloudId = useCloudId();
	const [isFetchingBlueprint, setIsFetchingBlueprint] = useState(false);
	const [currentBlueprint, setCurrentBlueprint] = useState<ConfluenceBlueprint | undefined>();
	const [hasRetroBlueprintForSelectedSpace, setHasRetroBlueprintForSelectedSpace] = useState<
		boolean | undefined
	>();
	const isSprintRetrosFlowChangeEnabled = fg('jsw_sprint_retros_flow_change');

	// Effect to update hook state when spaceKey changes. This will either fetch blueprints or use cached value.
	useEffect(() => {
		const fetchBlueprint = async (key: string) => {
			setIsFetchingBlueprint(true);
			const blueprint = await findRetroBlueprint(cloudId, key);
			setCurrentBlueprint(blueprint);
			spaceKeyToBlueprintCache.set(key, blueprint);
			setHasRetroBlueprintForSelectedSpace(
				blueprint?.blueprintModuleCompleteKey === RETROSPECTIVE_BLUEPRINT_COMPLETE_KEY,
			);
			setIsFetchingBlueprint(false);
		};

		// Prevent fetching if jsw_sprint_retros_flow_change feature gate is off or spaceKey is not set yet
		if (!isSprintRetrosFlowChangeEnabled || !spaceKey) return;

		if (spaceKeyToBlueprintCache.has(spaceKey)) {
			const blueprint = spaceKeyToBlueprintCache.get(spaceKey);
			setCurrentBlueprint(blueprint);
			setHasRetroBlueprintForSelectedSpace(
				blueprint?.blueprintModuleCompleteKey === RETROSPECTIVE_BLUEPRINT_COMPLETE_KEY,
			);
		} else {
			fetchBlueprint(spaceKey);
		}
	}, [cloudId, isSprintRetrosFlowChangeEnabled, spaceKey]);

	return { isFetchingBlueprint, currentBlueprint, hasRetroBlueprintForSelectedSpace };
};
