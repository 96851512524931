import React from 'react';
import type { StandupControls } from '../../common/types';
import { EndStandupButton } from './end-standup-button';
import { StartStandupButton } from './start-standup-button';

export const StandupControlButtons = ({
	isActiveStandup,
	setIsActiveStandup,
	isStandupDisabled,
}: StandupControls) => {
	return isActiveStandup ? (
		<EndStandupButton setIsActiveStandup={setIsActiveStandup} />
	) : (
		<StartStandupButton
			isStandupDisabled={isStandupDisabled}
			setIsActiveStandup={setIsActiveStandup}
		/>
	);
};
