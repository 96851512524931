import type { ConfluencePage, ConfluenceWhiteboard } from '../services/confluence/types.tsx';

// https://hello.atlassian.net/wiki/spaces/JFP/pages/3045472881/no-restricted-syntax+Don+t+declare+enums
export const RetroTypes = {
	WHITEBOARD: 'whiteboard',
	PAGE: 'page',
} as const;

export type RetroType = (typeof RetroTypes)[keyof typeof RetroTypes];

export type State = {
	sprintId: string | undefined;
	shouldCreateRetro: boolean;
	loadCreateRetroModal: boolean;
	loadCrossFlowModal: boolean;
	loadCrossJoinModal: boolean;
	isRetroLinked: boolean;
	isRetroLinking: boolean;
	retroLinkingError: Error | undefined;
	isRetroPublished: boolean;
	retroType: RetroType;
	confluenceData: ConfluenceData;
	createRetroPage: AsyncState<ConfluencePage>;
	createWhiteboard: AsyncState<ConfluenceWhiteboard>;
	isWhiteboardsDisabled: boolean;
};

export type ConfluenceData = {
	spaceKey?: string;
	parentPageId?: string;
	spaceId?: string;
};

export type AsyncState<T> = {
	data?: T;
	loading: boolean;
	error?: Error;
};
