import { useCallback } from 'react';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import {
	isCompleteSprintCrossFlowEnabled,
	isCompleteSprintCrossJoinEnabled,
} from '../../common/utils';
import { useRetros } from '../index';

export const useShowCreateRetroOrCrossSellModal = () => {
	const isCrossflowFFEnabled = isCompleteSprintCrossFlowEnabled();
	const isCrossjoinFFEnabled = isCompleteSprintCrossJoinEnabled();
	const [retrosState, retrosActions] = useRetros();
	const licensedProducts = useLicensedProducts();
	const { hasAccess } = useUserHasConfluenceAccess();

	return useCallback(() => {
		if (!retrosState.shouldCreateRetro) {
			return;
		}

		let action = retrosActions.setLoadCreateRetroModal;

		if (isCrossflowFFEnabled && isCrossjoinFFEnabled) {
			if (!licensedProducts.confluence) {
				action = retrosActions.setLoadCrossFlowModal;
			} else if (!hasAccess) {
				action = retrosActions.setLoadCrossJoinModal;
			}
		} else if (isCrossflowFFEnabled && !licensedProducts.confluence) {
			action = retrosActions.setLoadCrossFlowModal;
		} else if (isCrossjoinFFEnabled && licensedProducts.confluence && !hasAccess) {
			action = retrosActions.setLoadCrossJoinModal;
		}

		action(true);
	}, [
		isCrossflowFFEnabled,
		isCrossjoinFFEnabled,
		licensedProducts,
		hasAccess,
		retrosState.shouldCreateRetro,
		retrosActions,
	]);
};
