import React, { type ReactNode } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { jiraStandupEntryPoint } from '../../../entrypoint';

export const StandupEntryPointContext = createUseEntryPointContext<typeof jiraStandupEntryPoint>();

export const StandupEntryPointContextProvider = ({ children }: { children: ReactNode }) => {
	const entryPointResult = useEntryPoint(jiraStandupEntryPoint, {});

	return (
		<StandupEntryPointContext.Provider value={entryPointResult}>
			{children}
		</StandupEntryPointContext.Provider>
	);
};
