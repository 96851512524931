export const shouldDisableStandup = ({
	boardType,
	isSprintsEnabled,
	hasActiveSprint,
}: {
	boardType: string | undefined;
	isSprintsEnabled: boolean;
	hasActiveSprint: boolean;
}) => {
	if (boardType === 'Kanban') {
		return false;
	}

	return isSprintsEnabled && !hasActiveSprint;
};
