/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, styled, jsx } from '@compiled/react';
import type { PreloadedQuery } from 'react-relay';
import { Box, xcss, media, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { viewBoardCustomFiltersQuery } from '@atlassian/jira-relay/src/__generated__/viewBoardCustomFiltersQuery.graphql';
import { ImportWorkButton } from '@atlassian/jira-software-onboarding-invites/src/ui/import-work-button/async.tsx';
import { useShowQuickFilters } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { StandupControlButtons } from '@atlassian/jira-standups/src/ui/control-buttons/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { Capability } from '../../../common/capability';
import { useIsEmbedView } from '../../../common/hooks/use-is-embed-view/index.tsx';
import {
	useCapability,
	useIsCMPBoard,
	useIsIncrementPlanningBoard,
	useIsJSWBoard,
} from '../../../state/state-hooks/capabilities';
import InsightsButton from '../insights-button';
import { shouldDisableStandup } from '../utils.tsx';
import FilterBar from './filter-bar';
import FilterSelectionBar from './filter-selection-bar';
import { FullscreenButton } from './fullscreen-button';
import SwimlaneSwitch from './swimlane-switch';
import ViewSettings from './view-settings';

type Props = {
	isSprintsEnabled: boolean;
	hasActiveSprint: boolean;
	rapidViewId: number;
	customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery> | null | undefined;
	leftHandSideActions?: ReactNode;
	children?: ReactNode;
	showCardDetails?: boolean;
	boardType?: string;
	isActiveStandup: boolean;
	setIsActiveStandup: React.Dispatch<React.SetStateAction<boolean>>;
};

const ControlsBar = ({
	rapidViewId,
	isSprintsEnabled,
	hasActiveSprint,
	customFiltersQueryRef,
	leftHandSideActions,
	showCardDetails = true,
	isActiveStandup,
	setIsActiveStandup,
	boardType,
	children,
}: Props) => {
	const appEditions = useAppEditions();
	const showInsightsButton = !isSprintsEnabled || hasActiveSprint;
	const isCMPBoard = useIsCMPBoard();
	const isJSWBoard = useIsJSWBoard();
	const isIncrementPlanningBoard = useIsIncrementPlanningBoard();
	const isFilterBarVisible = useShowQuickFilters();
	const isTabNavigation = useCapability(Capability.IS_TAB_NAVIGATION);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isEmbedView = fg('jira_board_smartlink_embed') ? useIsEmbedView() : false;

	const isStandupDisabled = shouldDisableStandup({ boardType, isSprintsEnabled, hasActiveSprint });

	const shouldRenderGroupBy = () => {
		/**
		 * Increment Planning board only has one swimlane grouping - Team (rendered by default).
		 * We don't allow users to switch to a different grouping therefore we're not rendering the SwimlaneSwitcher.
		 */
		if (isIncrementPlanningBoard) {
			return false;
		}

		return true;
	};
	/*
	 * lg and above -> filter bar grows, actions do not shrink or grow. Actions at full size.
	 * will adapt to screen size by putting things into more.
	 * md -> lg: filter bar has basis of 70%, actions shrink and wrap onto two lines (with small buttons grouped together) if they can't fit in the remaining space.
	 * sm and below -> actions grow and wraps above the filter bar. Filter bar and actions grow.
	 */
	if (isTabNavigation) {
		return (
			<Box xcss={outerBoxStylesNewStyles}>
				<Box xcss={combinedFilterAndActionStyles} testId="software-board.header.controls-bar">
					<Box xcss={filterBarWrapperStyles}>
						{/* @ts-expect-error - TS2741 - Property 'screenName' is missing in type '{ customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery, Record<string, unknown>> | null | undefined; }' */}
						<FilterBar
							isActiveStandup={isActiveStandup}
							customFiltersQueryRef={customFiltersQueryRef}
						/>
						{isFilterBarVisible && isCMPBoard && <FilterSelectionBar />}
					</Box>
					<Box xcss={rightSideActionsStyles}>
						{shouldRenderGroupBy() && <SwimlaneSwitch />}
						{leftHandSideActions}
						<Flex gap="space.100" justifyContent="end" alignItems="center">
							<ImportWorkButton />
							{showInsightsButton && (
								<InsightsButton
									boardId={rapidViewId}
									edition={appEditions.software}
									isSprintsEnabled={isSprintsEnabled}
								/>
							)}
							{!isIncrementPlanningBoard &&
								isJSWBoard &&
								expVal('jira-in-product-standups', 'is_enabled', false) && (
									<StandupControlButtons
										isStandupDisabled={isStandupDisabled}
										isActiveStandup={isActiveStandup}
										setIsActiveStandup={setIsActiveStandup}
									/>
								)}
							{!isEmbedView && <FullscreenButton boardId={Number(rapidViewId)} />}
							<ViewSettings showCardDetails={showCardDetails} />
						</Flex>
						{children}
					</Box>
				</Box>
			</Box>
		);
	}

	return (
		// eslint-disable-next-line @atlaskit/design-system/use-primitives
		<div css={styles}>
			<Wrapper data-testid="software-board.header.controls-bar">
				<FilterBarWrapper>
					{/* @ts-expect-error - TS2741 - Property 'screenName' is missing in type '{ customFiltersQueryRef: PreloadedQuery<viewBoardCustomFiltersQuery, Record<string, unknown>> | null | undefined; }' */}
					<FilterBar
						isActiveStandup={isActiveStandup}
						customFiltersQueryRef={customFiltersQueryRef}
					/>
				</FilterBarWrapper>

				{shouldRenderGroupBy() && <SwimlaneSwitch />}

				{leftHandSideActions}
				<Box xcss={importWorkWrapperStyles}>
					<ImportWorkButton />
				</Box>
				{showInsightsButton && (
					<InsightsWrapper>
						<InsightsButton
							boardId={rapidViewId}
							edition={appEditions.software}
							isSprintsEnabled={isSprintsEnabled}
						/>
					</InsightsWrapper>
				)}

				<ViewSettings showCardDetails={showCardDetails} />

				{children}
			</Wrapper>
			{isFilterBarVisible && isCMPBoard && <FilterSelectionBar />}
		</div>
	);
};

export default ControlsBar;
const outerBoxStylesNewStyles = xcss({ marginBottom: 'space.200' });
const styles = css({ marginBottom: token('space.400', '32px') });

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	whiteSpace: 'nowrap',
});

const filterBarWrapperStyles = xcss({
	flex: '1 1 auto',
	display: 'flex',
	flexDirection: 'column',
	// minimum width required for expanded search + More button + clear filters
	minWidth: '468px',
	justifyContent: 'end',
	[media.above.md]: {
		flex: '0 1 70%',
	},
	[media.above.lg]: {
		flex: '1 1 auto',
	},
});
const rightSideActionsStyles = xcss({
	display: 'flex',
	flex: '1 1 auto',
	gap: 'space.100',
	justifyContent: 'flex-end',
	alignItems: 'start',
	flexWrap: 'wrap',
	[media.above.md]: {
		flex: '0 1 auto',
	},
});

const combinedFilterAndActionStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	gap: 'space.100',
	flexWrap: 'wrap-reverse',
	alignItems: 'stretch',
	[media.above.md]: {
		flexWrap: 'nowrap',
	},
});

// If updating this component, also update filterBarWrapperStyles
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterBarWrapper = styled.div({
	flex: 1,
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InsightsWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '5px',
});

const importWorkWrapperStyles = xcss({
	marginLeft: 'space.050',
});
