import React, { useCallback, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import DropdownMenu, {
	type CustomTriggerProps,
	type OnOpenChangeArgs,
	DropdownItem,
	DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import ExpandIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { type SwimlaneMode, NO_SWIMLANE } from '../../../../model/swimlane/swimlane-modes';
import messages from './messages';

type Props = {
	swimlaneModes: SwimlaneMode[];
	setSwimlaneMode: (arg1: SwimlaneModeId, arg2?: UIAnalyticsEvent) => void;
	currentSwimlaneMode: SwimlaneModeId | null | undefined;
};

const SwimlaneSwitch = ({ swimlaneModes, setSwimlaneMode, currentSwimlaneMode }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const dropdownMenuRef = useRef<HTMLDivElement>(null);

	const onCloseFocusHandler = () => {
		requestAnimationFrame(() => dropdownMenuRef.current?.querySelector('button')?.focus());
	};

	const onDropdownOpenChange = ({ isOpen }: OnOpenChangeArgs) => {
		setIsDropdownOpen(isOpen);
		if (!isOpen) {
			onCloseFocusHandler();
		}
	};

	const renderTrigger = useCallback(
		({ triggerRef, ...triggerProps }: CustomTriggerProps) => {
			const swimlaneMode: SwimlaneMode =
				swimlaneModes.find(({ id }) => id === currentSwimlaneMode) || NO_SWIMLANE;
			let swimlaneModeText = formatMessage(swimlaneMode.message, swimlaneMode.values);

			if (isVisualRefreshEnabled()) {
				swimlaneModeText =
					swimlaneMode && swimlaneMode.id !== NO_SWIMLANE.id
						? formatMessage(swimlaneMode.message, swimlaneMode.values)
						: '';
			}

			// Hide the icon if there is swimlane mode text
			const iconAfter =
				// eslint-disable-next-line no-nested-ternary
				isVisualRefreshEnabled() && swimlaneModeText ? undefined : fg(
						'tnk-1613-jira-visual-refresh-jsw-board-icons-2',
				  ) ? (
					<ChevronDownIcon
						label=""
						LEGACY_fallbackIcon={ExpandIcon}
						LEGACY_size="medium"
						color={token('color.icon', '#44546f')}
						LEGACY_primaryColor={token('color.icon', '#44546f')}
					/>
				) : (
					<ExpandIcon size="medium" label="" primaryColor={token('color.icon', '#44546f')} />
				);

			const trigger = (
				<Button
					{...triggerProps}
					ref={triggerRef}
					id="swimlane-switch-menu-trigger"
					aria-label={
						isVisualRefreshEnabled()
							? formatMessage(messages.groupCardsByAccessability, {
									label: swimlaneModeText,
								})
							: undefined
					}
					iconAfter={iconAfter}
					isSelected={
						triggerProps.isSelected || (isVisualRefreshEnabled() && Boolean(swimlaneModeText))
					}
				>
					<TriggerContainer>
						<TriggerLabel>
							{!isVisualRefreshEnabled() && swimlaneModeText}
							{isVisualRefreshEnabled() && formatMessage(messages.groupCardsBy)}
							{swimlaneModeText && isVisualRefreshEnabled() && `: ${swimlaneModeText}`}
						</TriggerLabel>
					</TriggerContainer>
				</Button>
			);

			return isDropdownOpen ? trigger : <Tooltip content={swimlaneModeText}>{trigger}</Tooltip>;
		},
		[currentSwimlaneMode, formatMessage, isDropdownOpen, swimlaneModes],
	);

	const onDropdownItemClick = useCallback(
		(swimlaneModeId: SwimlaneModeId) => () => {
			fireUIAnalytics(createAnalyticsEvent({ actionSubject: 'dropdownItem', action: 'clicked' }), {
				name: 'setSwimlaneMode',
				swimlaneMode: swimlaneModeId,
			});
			setSwimlaneMode(swimlaneModeId);
		},
		[setSwimlaneMode, createAnalyticsEvent],
	);

	return (
		<SwimlaneSwitchContainer
			data-testid="software-board.header.controls-bar.swimlane-switch"
			ref={dropdownMenuRef}
		>
			{!isVisualRefreshEnabled() && (
				<SwimlaneSwitchLabel htmlFor="swimlane-switch-menu-trigger">
					{formatMessage(messages.groupCardsByOld)}
				</SwimlaneSwitchLabel>
			)}
			<DropdownMenu
				isOpen={isDropdownOpen}
				onOpenChange={onDropdownOpenChange}
				trigger={renderTrigger}
				placement="bottom-end"
			>
				<DropdownItemGroup>
					{swimlaneModes.map(({ id, message, values }) => (
						<DropdownItem
							key={id}
							data-testid="software-board.header.controls-bar.swimlane-switch.dropdown-item"
							onClick={onDropdownItemClick(id)}
						>
							{formatMessage(message, values)}
						</DropdownItem>
					))}
				</DropdownItemGroup>
			</DropdownMenu>
		</SwimlaneSwitchContainer>
	);
};

export default SwimlaneSwitch;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneSwitchContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SwimlaneSwitchLabel = styled.label({
	font: token('font.body.small'),
	color: token('color.text.subtlest'),
	textTransform: 'uppercase',
	padding: `0 ${token('space.100', '8px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerContainer = styled.span({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerLabel = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 15}px`,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
