import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	startStandupButtonNonfinal: {
		id: 'standups.control-buttons.start-standup-button-nonfinal',
		defaultMessage: 'Start stand-up',
		description: 'Text on button for users to start a stand-up happening at the moment',
	},
	disabledButtonTooltipNonfinal: {
		id: 'standups.control-buttons.start-standup-button.disabled-button-tooltip-nonfinal',
		defaultMessage: 'A sprint needs to be started in the backlog before you can begin stand-up',
		description: 'Tooltip message for disabled start stand-up button',
	},
});
